import { ChainId, Currency, Pair, WNATIVE } from '@pancakeswap/sdk'
// import useTheme from 'hooks/useTheme'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { keyframes } from 'styled-components'
// import BnbWbnbNotice from './BnbWbnbNotice'
// import { BNB_ADDRESS } from './constants'
// import PriceChart from './PriceChart'
// import RefreshIcon from 'components/Svg/RefreshIcon'
import {
  ArrowDownIcon,
  ArrowUpDownIcon,
  ArrowUpIcon,
  ChartIcon,
  Flex,
  HistoryIcon,
  IconButton,
} from '@pancakeswap/uikit'
import { useExchangeChartViewManager } from 'state/user/hooks'
import { ChartViewMode } from 'state/user/actions'
import { AnimatePresence, motion } from 'framer-motion'
// import { BUSD, USDC } from '@pancakeswap/tokens'
import getLpAddress from 'utils/getLpAddress'
import { useContractRead, useProvider } from 'wagmi'
import useSWR from 'swr'
import { usePairContract } from 'hooks/useContract'
import { FAST_INTERVAL } from 'config/constants'
import { getTokenAddress } from './utils'
// import TrendingContainer from '../Trending/TrendingContainer'
// import { current } from '@reduxjs/toolkit'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const ChartFrame = styled.iframe<{ Mobile: boolean }>`
  ${(props) => (props.Mobile ? 'bottom: 0;' : null)}
`

const ChartContainer = styled.div`
  position: relative;
  width: 1%;
  height: 530px;
`

const TrendContainer = styled.div`
  position: relative;
  bottom: 0;
`

const SwitchContainer = styled.div<{ Mobile: boolean }>`
  position: relative;
  ${(props) => (props.Mobile ? 'bottom: 150px' : 'bottom: 38px;')}
  ${(props) => (props.Mobile ? 'left: 35%;' : 'left: 4px;')}
`

const SwitchIconButton = styled(IconButton)`
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  .icon-up-down {
    display: none;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    .icon-down {
      display: none;
      fill: white;
    }
    .icon-up {
      display: none;
      fill: white;
    }
    .icon-up-down {
      display: block;
      fill: white;
    }
    .icon-history {
      display: block;
      fill: white;
    }
  }
  margin-right: 3px;
`

const DexIconButton = styled(IconButton)`
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    .icon-chart {
      fill: white;
    }
  }
  margin-right: 3px;
`

type PriceChartContainerProps = {
  inputCurrencyId: string
  inputCurrency: Currency
  outputCurrencyId: string
  outputCurrency: Currency
  isChartExpanded: boolean
  setIsChartExpanded: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed: boolean
  currentSwapPrice: {
    [key: string]: number
  }
  isMobile?: boolean
  isFullWidthContainer?: boolean
  currentChainId?: number
}

const PriceChartContainer: React.FC<React.PropsWithChildren<PriceChartContainerProps>> = ({
  inputCurrencyId,
  inputCurrency,
  outputCurrency,
  outputCurrencyId,
  isChartExpanded,
  setIsChartExpanded,
  isChartDisplayed,
  isMobile,
  isFullWidthContainer = false,
  currentSwapPrice,
  currentChainId,
}) => {
  const wnative = WNATIVE[currentChainId]
  let token0Address = getTokenAddress(inputCurrencyId)
  let token1Address = getTokenAddress(outputCurrencyId)
  const [isPairReversed, setIsPairReversed] = useState(false)
  const togglePairReversed = useCallback(() => setIsPairReversed((prePairReversed) => !prePairReversed), [])
  const [isChart, setChart] = useState(0)
  const [isShowTrades, setIsShowTrades] = useState(false)
  const toggleShowTrades = useCallback(() => setIsShowTrades((preShowTrades) => !preShowTrades), [])
  const [chartView, setChartView] = useExchangeChartViewManager()
  // const [pairExists, setPairExists] = useState(false)

  const provider = useProvider({ chainId: currentChainId })

  const isNativePair = (currency1: Currency, currency2: Currency) =>
    (currency1?.isNative && currency2?.wrapped.equals(wnative)) ||
    (currency2?.isNative && currency1?.wrapped.equals(wnative))

  const getPairAddress = () => {
    if (isNativePair(inputCurrency, outputCurrency)) return '0x39d34dC54e47ba6f4ABb500a1f4F0Ea9DFC7FeeD'

    return getLpAddress(
      inputCurrency?.isNative ? wnative : token0Address,
      outputCurrency?.isNative ? wnative : token1Address,
      currentChainId,
    )
  }

  const pairAddress = getPairAddress()
  const pairContract = usePairContract(pairAddress)

  const pairExists = useMemo(async () => {
    if (!pairContract) {
      return false
    }
    try {
      const reserves = await pairContract.connect(provider).getReserves()
      if (!reserves) {
        return false
      }
      const { reserve0, reserve1 } = reserves
      return true
    } catch {
      return false
    }
  }, [pairContract, provider])

  const incrementChart = () => {
    if (chartView === ChartViewMode.DEXSCREENER) {
      setChart(0)
    } else if (chartView === ChartViewMode.GECKOTERMINAL) {
      setChart(1)
    } else if (chartView === ChartViewMode.WEB3SHARDS) {
      if (currentChainId === ChainId.DOGECHAIN) {
        setChart(1)
      } else {
        setChart(2)
      }
    }

    if (currentChainId === ChainId.DOGECHAIN) {
      if (isChart === 1) {
        setChart(0)
      } else {
        setChart(isChart + 1)
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (isChart === 2) {
        setChart(0)
      } else {
        setChart(isChart + 1)
      }
    }
    switch (isChart) {
      case 0:
        setChartView(ChartViewMode.DEXSCREENER)
        break
      case 1:
        setChartView(ChartViewMode.GECKOTERMINAL)
        break
      case 2:
        setChartView(ChartViewMode.WEB3SHARDS)
        break
      default:
        setChartView(ChartViewMode.DEXSCREENER)
    }
  }

  // const { isDark } = useTheme()

  if (!isChartDisplayed) {
    return null
  }

  let isDogechain = false
  if (currentChainId === ChainId.DOGECHAIN) {
    isDogechain = true
  }

if (inputCurrency?.isNative) token0Address = wnative.address
if (outputCurrency?.isNative) token1Address = wnative.address


  function chartURL(): string {
    const trades = isShowTrades === true ? '1' : '0'

    let chainName = 'dogechain'
    let url = `https://dexscreener.com/shibarium/${
      isPairReversed ? token0Address : token1Address
    }?embed=1&theme=dark&trades=${trades}&info=0`

    if (currentChainId === ChainId.SHIBARIUM) {
      isDogechain = false
      chainName = 'shibarium'

      // eslint-disable-next-line no-lonely-if
      if (chartView === ChartViewMode.DEXSCREENER) {
        url = `https://dexscreener.com/${chainName}/${
          pairExists ? pairAddress : isPairReversed ? token0Address : token1Address
        }?embed=1&theme=dark&trades=${trades}&info=0`
      } else if (chartView === ChartViewMode.WEB3SHARDS) {
        url = `https://web3shards.io/chart?chain=shibarium&theme=dark&mode=compact&stats=false&apiKey=4aa285fe-7077-4d62-b9a9-ca42e1c5ff91&token=${
          isPairReversed ? token0Address : token1Address
        }&socketId=b048b37f-fcba-4f27-833e-e871abb32474`
      } else if (chartView === ChartViewMode.GECKOTERMINAL) {
        // url = `https://www.geckoterminal.com/${chainName}/pools/${isPairReversed ? token0Address : token1Address}?embed=1&info=0&swaps=${trades}`
        url = `https://dexscreener.com/${chainName}/${
          pairExists ? pairAddress : isPairReversed ? token0Address : token1Address
        }?embed=1&theme=dark&trades=${trades}&info=0`
      }
    } else {
      isDogechain = true
      chainName = 'dogechain'
      // console.log(`Dogechain Chart: ${chartView}`);
      if (chartView === ChartViewMode.DEXSCREENER) {
        url = `https://dexscreener.com/${chainName}/${
          pairExists
            ? pairAddress ?? (isPairReversed ? token0Address : token1Address)
            : isPairReversed
            ? token0Address
            : token1Address
        }?embed=1&theme=dark&trades=${trades}&info=0`
      } else if (chartView === ChartViewMode.WEB3SHARDS) {
        url = `https://www.geckoterminal.com/${chainName}/pools/${
          pairExists
            ? pairAddress ?? (isPairReversed ? token0Address : token1Address)
            : isPairReversed
            ? token0Address
            : token1Address
        }?embed=1&info=0&swaps=${trades}`
      } else if (chartView === ChartViewMode.GECKOTERMINAL) {
        // url = `https://www.geckoterminal.com/${chainName}/pools/${isPairReversed ? token0Address : token1Address}?embed=1&info=0&swaps=${trades}`
        url = `https://dexscreener.com/${chainName}/${
          pairExists
            ? pairAddress ?? (isPairReversed ? token0Address : token1Address)
            : isPairReversed
            ? token0Address
            : token1Address
        }?embed=1&theme=dark&trades=${trades}&info=0`
      } else {
        url = `https://dexscreener.com/${chainName}/${
          pairExists
            ? pairAddress ?? (isPairReversed ? token0Address : token1Address)
            : isPairReversed
            ? token0Address
            : token1Address
        }?embed=1&theme=dark&trades=${trades}&info=0`
      }
    }

   /* console.log(
      url,
      ' ',
      token1Address,
      ' ',
      token0Address,
      ' ',
      pairAddress,
      ' ',
      pairExists,
      ' ',
      isPairReversed,
      ' ',
      isShowTrades,
      ' ',
      chartView,
      ' ',
      currentChainId,
      ' ',
      chainName,
    ) */

    return url
  }

  // const isBnbWbnb = token0Address === BNB_ADDRESS && token1Address === BNB_ADDRESS

  /* if (isBnbWbnb) {
    return <BnbWbnbNotice isDark={isDark} isChartExpanded={isChartExpanded} />
  } */

  let frameHeight = '525px'
  if (isShowTrades) {
    frameHeight = '625px'
  }

  return (
    <>
      <AnimatePresence>
        <Flex
          width="100%"
          flexDirection="column"
          justifyContent="stretch"
          position="relative"
          alignItems="stretch"
          justifyItems="flex-start"
        >
          <ChartContainer
            as={motion.div}
            layout
            initial={{ width: 0 }}
            animate={{ width: '100%' }}
            exit={{ width: 0 }}
            transition={{
              duration: 1,
            }}
          >
            <ChartFrame
              as={motion.iframe}
              initial={{ opacity: 0, scale: 0.05 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.05 }}
              transition={{
                opacity: { duration: 2, delay: 1 },
                scale: { duration: 2, delay: 1.1 },
              }}
              Mobile={isMobile}
              className="dexchart_iframe"
              src={`${chartURL()}`}
              name="web3-shards-chart"
              height={isMobile ? '100%' : `${frameHeight}`}
              width="100%"
              title="web3-shards-chart"
            />
            <SwitchContainer
              as={motion.div}
              layout
              initial={{ opacity: 0, scale: 0.7 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              transition={{ opacity: { delay: 2, duration: 2.5 }, scale: { delay: 2, duration: 2 } }}
              Mobile={isMobile}
            >
              <DexIconButton
                variant="light"
                scale="sm"
                aria-label="Cycle Chart View"
                title="Cycle Different Charts"
                onClick={() => {
                  incrementChart()
                }}
              >
                <ChartIcon className="icon-chart" color="primary" />
              </DexIconButton>
              <SwitchIconButton
                variant="light"
                scale="sm"
                aria-label="Chart Input or Output Token"
                title="Cycle Different Charts"
                onClick={togglePairReversed}
              >
                <ArrowUpDownIcon className="icon-up-down" color="primary" />
                {isPairReversed ? (
                  <ArrowDownIcon className="icon-down" color="primary" />
                ) : (
                  <ArrowUpIcon className="icon-up" color="primary" />
                )}
              </SwitchIconButton>
              <SwitchIconButton
                variant="light"
                scale="sm"
                aria-label="Toggle Show Trades"
                title="Toggle Show Trades"
                onClick={toggleShowTrades}
              >
                <HistoryIcon className="icon-history" color="primary" />
              </SwitchIconButton>
            </SwitchContainer>
          </ChartContainer>
          {/* !isMobile && (
            <TrendingContainer isChartDisplayed={isChartDisplayed} isChartExpanded />
          ) */}
        </Flex>
      </AnimatePresence>
    </>
  )
}
/*
   <PriceChart
     token0Address={isPairReversed ? token1Address : token0Address}
     token1Address={isPairReversed ? token0Address : token1Address}
     inputCurrency={isPairReversed ? outputCurrency : inputCurrency}
     outputCurrency={isPairReversed ? inputCurrency : outputCurrency}
     onSwitchTokens={togglePairReversed}
     isDark={isDark}
     isChartExpanded={isChartExpanded}
     setIsChartExpanded={setIsChartExpanded}
     isMobile={isMobile}
     isFullWidthContainer={isFullWidthContainer}
     currentSwapPrice={currentSwapPrice}
   />
 */
export default PriceChartContainer
